@font-face {
  font-family: 'Avenir Next';
  font-weight: 300;
  src: local('Avenir Next'), url(./Fonts/AvenirNext-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 400;
  src: local('Avenir Next'), url(./Fonts/AvenirNext-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 500;
  src: local('Avenir Next'), url(./Fonts/AvenirNext-500.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 600;
  src: local('Avenir Next'), url(./Fonts/AvenirNext-600.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 700;
  src: local('Avenir Next'), url(./Fonts/AvenirNext-700.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 900;
  src: local('Avenir Next'), url(./Fonts/AvenirNext-900.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Leaflet Container */
.leaflet-container {
  height: 100%;
}

/* Input Type Time */
input[type='datetime-local']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(66%) sepia(14%) saturate(441%) hue-rotate(182deg) brightness(92%) contrast(90%);
}

.MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
  margin-top: 0px !important;
}

.agora_video_player {
  object-fit: contain !important;
}

.dropdown-menu {
  margin-top: 30px;
}

.dropdown-menu .MuiMenu-paper {
  overflow-y: unset;
  overflow-x: unset;
}

.dropdown-menu .MuiMenu-paper:nth-child(3) {
  border-radius: 20px;
}

.dropdown-menu ul {
  padding: 0;
}

.dropdown-menu ul li {
  overflow: hidden;
}

.MuiPhoneNumber-flagButton {
  display: block; /* for safari */
  width: 10px;
}

a {
  text-decoration: none;
}

/* Custom Time Picker */
.PrivatePickersToolbar-dateTitleContainer .MuiButton-text {
  height: auto;
}

.PrivatePickersToolbar-dateTitleContainer .MuiTypography-h3 {
  font-size: 2.8rem;
}

/* Overrides For chat screen */
.str-chat__avatar {
  cursor: pointer;
}

#cloudsponge-root-holder {
  z-index: 999999999;
  position: relative;
}

.MuiModal-root {
  z-index: 999999999;
}

.SnackbarContainer-root {
  z-index: 9999999999 !important;
}
