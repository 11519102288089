.intro-overlay {
    position: absolute;
    box-sizing: content-box;
    z-index: 999998;
    opacity: 0.3;
    background-color: black;
    transition: all 0.3s ease-out;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: "fixed";
}
